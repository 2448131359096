.MAP {
  background-color: #f0f0f0;
}

.mapContainer {
  position: relative;
}

.unit {
  stroke: #aaaaaaaa;
  stroke-width: 0.5;
  fill-opacity: 1;
  transition: all 0.5s;
  z-index: 100;
}
.unit:hover {
  stroke: #ffaaaaaa;
  fill-opacity: 0.7;
  z-index: 1000;
}

.UNOCCUPIED {
  composes: unit;
  fill: #000;
}
.UNOCCUPIED_DAMAGED {
  composes: unit;
  fill: #eeee00;
}
.OCCUPIED_CURRENT {
  composes: unit;
  fill: #39a139;
}
.OCCUPIED_LOCKED_OUT {
  composes: unit;
  fill: #0000ff;
}
.OCCUPIED_IN_GRACE {
  composes: unit;
  fill: #00ffff;
}
.OCCUPIED_PAST_DUE {
  composes: unit;
  fill: #ff0000;
}
.OCCUPIED_AUCTION {
  composes: unit;
  fill: #eeeeee;
}
.CUSTOM {
  composes: unit;
  fill: #7dff5d;
}

.unitText {
  font-size: 10px;
  transform: translate(-50%, -50%);
}
